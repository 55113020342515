import React from "react"
import Layout from "../../../components/sermonLayoutPhoenix"
import SEO from "../../../components/seo"

const SermonPost = () => (
  <Layout sermonSrc="https://www.youtube.com/embed/Bo1PqvYG8rg">
    <SEO title="The Blessing - Touching Holiness" />
  </Layout>
)

export default SermonPost
